<template>
  <v-card
    :loading="loading"
    class="mx-auto"
    width="374"
    elevation="0"
    style="border-radius: 0"
  >
    <v-card-text class="teal--text text--lighten-1 text-center"><h3>Клиенты</h3></v-card-text>

    <div>
      <v-text-field
        v-model="filter"
        prepend-icon="mdi-account-search"
        label="Поиск"
        color="teal lighten-1"
      ></v-text-field>

      <v-card
        v-for="client in filteredClients(filter)"
        :key="client._id"
        class="pl10 mt10 mx-1 elevation-5 rounded"
        :dark="getBlackPhones().includes(client.phone)"
      >
        <v-row justify="end">
          <v-menu
            min-width="90" offset-x left top origin="top"
            transition="slide-y-reverse-transition"
            content-class="elevation-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small fab v-bind="attrs" v-on="on"
                     class="transparent elevation-0 mr-7 mt-5 teal--text"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-row v-if="!getBlackPhones().includes(client.phone)" justify="center" class="my-4">
              <v-btn @click="putInBlackHandler(client)"
                     fab color="black" elevation="5" class="grey--text text--lighten-1">
                <v-icon>mdi-account-off</v-icon>
              </v-btn>
            </v-row>

            <v-row v-else justify="center" class="my-4">
              <v-btn @click="deleteFormBlackList(client)"
                     fab color="white" elevation="5" class="grey--text">
                <v-icon>mdi-account-check</v-icon>
              </v-btn>
            </v-row>

            <v-row justify="center" class="my-4">
              <v-btn @click="recordClientHandler(client)"
                     dark fab color="blue-grey darken-1" elevation="5">
                <v-icon>mdi-calendar-edit</v-icon>
              </v-btn>
            </v-row>

            <v-row justify="center" class="my-4">
              <v-btn @click="$router.push(`/records-of-client?id=${client._id}`)"
                     dark fab color="deep-purple lighten-2" elevation="5">
                <v-icon>mdi-account-details</v-icon>
              </v-btn>
            </v-row>

            <v-row justify="center" class="my-4">
              <v-btn dark fab color="teal" elevation="5" :href="`tel:${client.phone}`">
                <v-icon>mdi-phone</v-icon>
              </v-btn>
            </v-row>

          </v-menu>
        </v-row>

        <div style="margin-top: -55px">
          <v-card-text style="margin-top: -20px">
            {{ client.lastName }}
            {{ client.firstName }}
            <br>
            {{ client.phone }}
          </v-card-text>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: "Clients",

    data: () => ({
      loading: false,
      dialog: false,
      filter: "",
      blackList: [],
      clientList: []
    }),

    methods: {
      async loadRecordList() {
        this.loading = true
        const json = await this.authFetch('/record/load-all-records-date-regardless', {})
        this.records = json.records
        this.records.sort(this.sortRecordListFunc)
        this.loading = false
      },

      async loadClientList() {
        this.loading = true
        const json = await this.authFetch('/subscriber/load-subscriber-list', {})
        if (json.status === 200) {
          this.clientList = json.subscribers
          this.clientList.sort(this.sortClientListFunc)
        }
        this.loading = false
      },

      async loadBlackList() {
        this.loading = true
        const json = await this.authFetch('/black/load-black-list', {})
        this.blackList = json.blackList
        this.loading = false
      },

      getBlackPhones() {
        if (!this.blackList.length) return []
        return this.blackList.map(b => b.phone)
      },

      filteredClients(str) {
        if (!this.clientList.length) return []
        if (!this.filter) return this.clientList

        const filtered = this.clientList
          .map(c => JSON.stringify({firstName: c.firstName, lastName: c.lastName, phone: c.phone}))
          .filter(j => j.toLowerCase().includes(str.toLowerCase()))
          .map(j => JSON.parse(j))

        return this.clientList.filter(c => filtered.map(f => f.phone).includes(c.phone))
      },

      async putInBlackHandler(client) {
        if (!window.confirm("Вы уверены, что хотите внести клиента в черный список?")) return
        const {firstName, lastName, phone} = client
        this.loading = true
        await this.authFetch('/black/put-in-black-list', {firstName, lastName, phone})
        this.loading = false
        await this.loadBlackList()
      },

      async deleteFormBlackList(client) {
        const {phone} = client
        this.loading = true
        await this.authFetch('/black/delete-from-black-list', {phone})
        this.loading = false
        await this.loadBlackList()
      },

      recordClientHandler(client) {
        this.$store.commit("setFirstName", client.firstName)
        this.$store.commit("setLastName", client.lastName)
        this.$store.commit("setPhone", client.phone)
        this.$router.push("/choose-datetime-master")
      }
    },

    async mounted() {
      await this.loadClientList()
      // await this.loadRecordList()
      await this.loadBlackList()
    }

  }
</script>

<style scoped>

</style>
